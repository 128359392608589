<template>
	<div class="main qjc-theme-bg">
		<van-nav-bar
			title="健康大數據精準套餐購買"
			left-arrow
			fixed
			class="qjc-nav-bar"
			@click-left="$router.back()"
		/>
		
		<div class="combos qjc-margin-ct qjc-bg-fff">
			<ul class="combos-name qjc-c-dark qjc-fts-32 qjc-clearfix">
				<li v-for="(item, index) in combos.list" @click="checkCombo(index)" :class="{active: item.name == combos.active.name}" class="qjc-fl">
					<div>{{ item.name }}</div>
				</li>
			</ul>
			<div class="combos-detail">
				<van-collapse v-model="collapse">
					<van-collapse-item 
						:is-link="false"
						name="0"
						:border='false'
					>
						<div class="combos-detail-tit qjc-c-dark qjc-clearfix" slot="title">
							<div class="left qjc-fts-28 qjc-fl qjc-texta-l">
								<span class="qjc-ftw-b">總體健康評估和生命預測</span>
								<van-icon
									name="arrow-down"
									:class="{ on: /0/g.test(collapse) }"
								/>
							</div>
							<div 
								v-html="combos.active.overall" 
								class="right qjc-texta-c qjc-fr"
							></div>
						</div>
						<div slot="default" class="describe qjc-texta-l">
							<p>根据您的各项指标，评估您属于健康人群、亚健康人群还是医疗需求人群；</p>
							<p>评估您的预期寿命，并根据每一年的预期存活概率和预期失能概率绘制您的生命曲线。</p>
						</div>
					</van-collapse-item>
					<van-collapse-item 
						:is-link="false"
						name="1"
						:border='false'
					>
						<div class="combos-detail-tit qjc-c-dark qjc-clearfix" slot="title">
							<div class="left qjc-fts-28 qjc-fl qjc-texta-l">
								<span class="qjc-ftw-b">體型評估</span>
								<van-icon
									name="arrow-down"
									:class="{ on: /1/g.test(collapse) }"
								/>
							</div>
							<div 
								v-html="combos.active.body" 
								class="right qjc-texta-c qjc-fr"
							></div>
						</div>
						<div slot="default" class="describe qjc-texta-l">
							<p>根據您的體型指標，評估您的整體體型</p>
							<p>並針對體質指數BMI、腰臀比WHR和體脂率BFR進行專項大數據評估。</p>
						</div>
					</van-collapse-item>
					<van-collapse-item
						:is-link="false"
						name="2"
						:border='false'
					>
						<div class="combos-detail-tit qjc-c-dark qjc-clearfix" slot="title">
							<div class="left qjc-fts-28 qjc-fl qjc-texta-l">
								<span class="qjc-ftw-b">醫療費用預測</span>
								<van-icon
									name="arrow-down"
									:class="{ on: /2/g.test(collapse) }"
								/>
							</div>
							<div 
								v-html="combos.active.medical" 
								class="right qjc-texta-c qjc-fr"
							></div>
						</div>
						<div slot="default" class="describe qjc-texta-l">
							<p>根據您的健康狀況和所在居住地的醫療費用水平，預測您未來可能會承擔的醫療費用。</p>
						</div>
					</van-collapse-item>
					<van-collapse-item
						:is-link="false"
						name="3"
						:border='false'
					>
						<div class="combos-detail-tit qjc-c-dark qjc-clearfix" slot="title">
							<div class="left qjc-fts-28 qjc-fl qjc-texta-l">
								<span class="qjc-ftw-b">未來疾病風險預測</span>
								<van-icon
									name="arrow-down"
									:class="{ on: /3/g.test(collapse) }"
								/>
							</div>
							<div 
								v-html="combos.active.illness" 
								class="right qjc-texta-c qjc-fr"
							></div>
						</div>
						<div slot="default" class="describe qjc-texta-l">
							<p>根據您的健康狀況，通過大數據評估，給出不同的疾病風險預測</p>
							<p>健康人群：系統疾病風險（無同族群對比）</p>
							<p>亞健康人群：慢性病風險</p>
							<p>醫療需求人群：急重症風險</p>
						</div>
					</van-collapse-item>
					<van-collapse-item
						:is-link="false"
						name="4"
						:border='false'
					>
						<div class="combos-detail-tit qjc-c-dark qjc-clearfix" slot="title">
							<div class="left qjc-fts-28 qjc-fl qjc-texta-l">
								<span class="qjc-ftw-b">不患病增壽</span>
								<van-icon
									name="arrow-down"
									:class="{ on: /4/g.test(collapse) }"
								/>
							</div>
							<div 
								v-html="combos.active.noSick" 
								class="right qjc-texta-c qjc-fr"
							></div>
						</div>
						<div slot="default" class="describe qjc-texta-l">
							<p>若不患上某種疾病，相應可增壽的年數。</p>
							<p>(醫療需求人群不適用)</p>
						</div>
					</van-collapse-item>
					<van-collapse-item
						:is-link="false"
						name="5"
						:border='false'
					>
						<div class="combos-detail-tit qjc-c-dark qjc-clearfix" slot="title">
							<div class="left qjc-fts-28 qjc-fl qjc-texta-l">
								<span class="qjc-ftw-b">生化指標評估</span>
								<van-icon
									name="arrow-down"
									:class="{ on: /5/g.test(collapse) }"
								/>
							</div>
							<div 
								v-html="combos.active.biom" 
								class="right qjc-texta-c qjc-fr"
							></div>
						</div>
						<div slot="default" class="describe qjc-texta-l">
							<p>對您的各項生化指標進行大數據評估；</p>
							<p>根據生化指標進行更準確的疾病風險和健康風險預測。</p>
						</div>
					</van-collapse-item>
					<van-collapse-item
						:is-link="false"
						name="6"
						:border='false'
					>
						<div class="combos-detail-tit qjc-c-dark qjc-clearfix" slot="title">
							<div class="left qjc-fts-28 qjc-fl qjc-texta-l">
								<span class="qjc-ftw-b">同族群對比</span>
								<van-icon
									name="arrow-down"
									:class="{ on: /6/g.test(collapse) }"
								/>
							</div>
							<div 
								v-html="combos.active.group" 
								class="right qjc-texta-c qjc-fr"
							></div>
						</div>
						<div slot="default" class="describe qjc-texta-l">
							<p>將您的體型、疾病風險、生化指標等檢查結果和評估結果與同年齡、同性別的人群大數據進行對比。</p>
							<p>(健康人群部分結果不適用)</p>
						</div>
					</van-collapse-item>
					<van-collapse-item
						:is-link="false"
						name="7"
						:border='false'
					>
						<div class="combos-detail-tit qjc-c-dark qjc-clearfix" slot="title">
							<div class="left qjc-fts-28 qjc-fl qjc-texta-l">
								<span class="qjc-ftw-b">未來健康風險預測</span>
								<van-icon
									name="arrow-down"
									:class="{ on: /7/g.test(collapse) }"
								/>
							</div>
							<div 
								v-html="combos.active.health" 
								class="right qjc-texta-c qjc-fr"
							></div>
						</div>
						<div slot="default" class="describe qjc-texta-l">
							<p>根據您的健康狀況，通過大數據評估，給出進一步的健康風險預測</p>
							<p>健康人群：生理年齡；</p>
							<p>亞健康人群：三高風險；</p>
							<p>醫療需求人群：癌症風險（若有）</p>
						</div>
					</van-collapse-item>
					<van-collapse-item
						:is-link="false"
						name="8"
						:border='false'
					>
						<div class="combos-detail-tit qjc-c-dark qjc-clearfix" slot="title">
							<div class="left qjc-fts-28 qjc-fl qjc-texta-l">
								<span class="qjc-ftw-b">健康管理和改善方案</span>
								<van-icon
									name="arrow-down"
									:class="{ on: /8/g.test(collapse) }"
								/>
							</div>
							<div 
								v-html="combos.active.improve" 
								class="right qjc-texta-c qjc-fr"
							></div>
						</div>
						<div slot="default" class="describe qjc-texta-l">
							<p>根據您的各項數據和評估結果，通過大數據分析，給出為您量身打造的、循序漸進的改善目標及詳細具體的改善方案，包括體型、運動、飲食、生化指標四個方面。</p>
						</div>
					</van-collapse-item>
				</van-collapse>
				<div class="price">
					<sup>$</sup>
					<b>{{ combos.active.price }}</b>
					<sub>港元</sub>
				</div>
				<!-- <div class="org-price qjc-relative">
					原價：${{ combos.active.orgPrice }}港元
				</div> -->
			</div>
		</div>
		
		<van-image
			@click="pay"
			width="7.02rem"
			height="1.18rem"
			:src="require('@/assets/images/lijigoumaibutton.png')"
		/>
		
		<!-- 是否有检查结果或预约体检弹窗 -->
		<van-popup class="is-book" v-model="way.show" :close-on-click-overlay="false">
			<div class="way qjc-texta-l">
				<ul class="qjc-fts-28">
					<li>
						您需要提供:<br>
						您的基本個人信息、生活習慣、家族病史等。
						<p class="qjc-nowrap" style="letter-spacing: -0.01rem;">您的體型信息，包括身高、體重、三圍、體脂率等。</p>
					</li>
					<li>
						香港體檢會為您提供專業的體檢服務，檢測您的十六項生化指標信息，包括：<br>
						血液常規——白血球、血紅素、血小板;<br>
						血糖——空腹血糖、糖化血色素；<br>
						血脂肪——總膽固醇、低密度脂蛋白膽固醇、高密度脂蛋白膽固醇、三酸甘油脂；<br>
						肝膽功能——肝指數GOT、肝指數GPT、鹼性磷酸酶、白蛋白；<br>
						腎功能——肌酸酐、尿酸、腎絲球過濾率。
					</li>
					<li>請您在完成問卷資料的填寫後，預約您的體檢時間</li>
					<li>
						您也可以前往香港體檢線下門店直接購買體驗。<br>
						<router-link to="/address" class="qjc-c-primary">查看香港體檢門店地址及電話<van-icon name="arrow"/><van-icon name="arrow"/></router-link>
					</li>
					<li>完成體檢套餐的購買後，請在30天內完成信息填寫與體檢預約，逾期將導致套餐失效並無法退還已支付的款項。</li>
				</ul>
				<!-- <van-checkbox-group v-model="way.result" checked-color="#6681fa" @change="waySel">
					<van-checkbox name="0" icon-size="0.24rem" shape="square" class="qjc-c-dark qjc-fts-32">我有近期的體型和生化檢查結果。</van-checkbox>
					<van-checkbox name='1' icon-size="0.24rem" shape="square" class="qjc-c-dark qjc-fts-32">購買體檢套餐並預約線下體檢 (香港體檢 HK$ 580)。<span class="bk-price qjc-fts-24 qjc-relative">（原价HK$1980）</span></van-checkbox>
				</van-checkbox-group> -->
				<van-button @click="toQuestion" class="qjc-wid-100 qjc-ftw-b qjc-bg-primary qjc-fts-32 qjc-c-fff">確定</van-button>
				<p class="buy-tips qjc-texta-c qjc-fts-24">大數據評估項目僅適用於20-90歲人群</p>
			</div>
			<div @click="way.show = false" class="close qjc-absolute qjc-round">
				<van-image
					width="0.265rem"
					height="0.265rem"
					:src="require('@/assets/images/close.png')"
				/>
			</div>
		</van-popup>
		
		<!-- 评估未完成弹窗 -->
		<van-popup 
			v-model="complete" 
			:close-on-click-overlay="false"
			class="assess-nodone"
			:class="{ 'yjqz-alert': yjqz }"
		>
			<div v-if="yjqz" class="yjqz-box qjc-absolute">
				<van-image
					width="100%"
					height="100%"
					:src="require('@/assets/images/index_yjqz.png')"
				/>
				<van-button
					to="/question"
					round
					class="qjc-bg-ffff qjc-ftw-b qjc-fts-32 qjc-c-primary"
				>
					前往完成
				</van-button>
			</div>
			<div v-else>
				<van-image
					width="3.2rem"
					height="2.55rem"
					:src="require('@/assets/images/errorTip.png')"
				/>
				<p class="qjc-fts-32 qjc-c-dark">您上一次的健康大數據評估尚未完成，請先完成該次評估。</p>
				<van-button
					@click="$router.push('/')"
					round
					class="qjc-c-primary qjc-ftw-b qjc-borderc-primary qjc-fts-32"
				>
					返回首頁
				</van-button>
				<van-button
					to="/question"
					round
					class="qjc-bg-primary qjc-ftw-b qjc-fts-32 qjc-c-fff"
				>
					繼續完成
				</van-button>
			</div>
			<div @click="complete = false" class="close qjc-absolute qjc-round">
				<van-image
					width="0.265rem"
					height="0.265rem"
					:src="require('@/assets/images/close.png')"
				/>
			</div>
		</van-popup>
		
		<!-- 報告未生成 -->
		<van-popup
			v-model="hasReport"
			:close-on-click-overlay="false"
			class="has-report"
		>
			<van-image
				width="2.97rem"
				height="2.19rem"
				:src="require('@/assets/images/hasReport.png')"
			/>
			<div class="qjc-texta-l">
				您尚有未生成報告的已購買套餐，無法重復購買。<br>
				在您完成線下體檢後，我們會自動獲取您的生化指標結果並生成報告，請您耐心等待。
			</div>
			
			<van-button @click="hasReport = false" class="qjc-c-fff qjc-block qjc-ftw-b qjc-bg-primary qjc-fts-32" round>確認</van-button>
			
			<div @click="hasReport = false" class="close qjc-absolute qjc-round">
				<van-image
					width="0.265rem"
					height="0.265rem"
					:src="require('@/assets/images/close.png')"
				/>
			</div>
		</van-popup>
		
		<footer>最終解釋權歸上海米因大數據科技有限公司所有</footer>
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Collapse, CollapseItem, Icon, Field, Image, Popup, Checkbox, CheckboxGroup, Button } from 'vant'
	Vue.use(NavBar)
	   .use(Collapse)
	   .use(CollapseItem)
	   .use(Icon)
	   .use(Field)
	   .use(Image)
	   .use(Popup)
	   .use(Checkbox)
	   .use(CheckboxGroup)
	   .use(Button);
	   
	import { isPay, packagePrice } from "@/js/axios.js"
	
	export default{
		name: "buyCombo",
		data (){
			return {
				collapse: [],// 折叠面板展开数组
				combos: {
					active: {},// 当前显示套餐 默认为套餐列表第一个(created钩子里赋值)
					list: [
						{
							package: '',
							name: '--',//套餐名称
							overall: "<i class='iconfont iconduihao'></i>",//總體健康評估和生命預測
							body: "<i class='iconfont iconduihao'></i>",//體型評估
							medical: "未来3年",//醫療費用預測
							illness: "疾病種類：最多3種<br>風險預測：未來1年",//未來疾病風險預測
							noSick: "<i class='iconfont iconchahao'></i>",//不患病增壽
							biom: "<i class='iconfont iconchahao'></i>",//生化指標評估
							group: "<i class='iconfont iconchahao'></i>",//同族群對比
							health: "<i class='iconfont iconchahao'></i>",//未來健康風險預測
							improve: "體型、飲食",//健康管理和改善方案
							price: '--',//价格
							orgPrice: '--'//原價
						},//体验套餐
						{
							package: '',
							name: '--',//套餐名称
							overall: "<i class='iconfont iconduihao'></i>",//總體健康評估和生命預測
							body: "<i class='iconfont iconduihao'></i>",//體型評估
							medical: "未来5年",//醫療費用預測
							illness: "疾病種類：最多5種<br>風險預測：未來3年",//未來疾病風險預測
							noSick: "<i class='iconfont iconchahao'></i>",//不患病增壽
							biom: "<i class='iconfont iconduihao'></i>",//生化指標評估
							group: "<i class='iconfont iconduihao'></i>",//同族群對比
							health: "<i class='iconfont iconduihao'></i>",//未來健康風險預測
							improve: "體型、飲食、運動",//健康管理和改善方案
							price: '--',//价格
							orgPrice: '--'//原價
						},//精英套餐
						{
							package: '',
							name: '--',//套餐名称
							overall: "<i class='iconfont iconduihao'></i>",//總體健康評估和生命預測
							body: "<i class='iconfont iconduihao'></i>",//體型評估
							medical: "未来10年",//醫療費用預測
							illness: "疾病種類：全部結果<br>風險預測：未來5年",//未來疾病風險預測
							noSick: "<i class='iconfont iconduihao'></i>",//不患病增壽
							biom: "<i class='iconfont iconduihao'></i>",//生化指標評估
							group: "<i class='iconfont iconduihao'></i>",//同族群對比
							health: "<i class='iconfont iconduihao'></i>",//未來健康風險預測
							improve: "體型、飲食、運動、生化指標",//健康管理和改善方案
							price: '--',//价格
							orgPrice: '--'//原價
						}//尊享套餐
					]//套餐列表
				},// 套餐
				way: {
					show: false,//有体检结果或线下体检选择弹窗
					result: []//選框選擇結果
				},//體檢方式
				complete: false,// 评估未完成弹窗是否显示。默认true
				hasReport: false,// 報告是否生成
				
				yjqz: false// 未完成报告是否为疫境求真
			}
		},
		created: function(){
			// 获取套餐价格信息
			this.getPackageInfo(() => {
				// 显示所选套餐
				var p = sessionStorage.getItem('package');
				var	index = p == '1'?2:(p == '2'?1:0);
				this.combos.active = this.combos.list[index];
			});
		},
		methods: {
			getPackageInfo (callback){//获取套餐信息
				packagePrice().then(res => {
					if(res.status == 1){
						var result = res.data;
						
						// 疫境求真不显示
						result = result.filter(item => (item.id == '1' || item.id == '2' || item.id == '3'));
						
						//套餐详情data id是反过来的，即3-2-1，如新增套餐(id自增),直接从data前面unshift
						result.sort((a,b) => a-b );
						// console.log(result);
						for(var i=0;i<result.length;i++){
							this.combos.list[i].package = result[i].id;
							this.combos.list[i].name = result[i].name;
							this.combos.list[i].price = result[i].price;
							this.combos.list[i].orgPrice = result[i].cost_price;
						}
						
						callback?callback():'';
					}else{
						this.$router.push('/');
					}
				});
			},
			checkCombo (index){
				this.combos.active = this.combos.list[index];
				sessionStorage.setItem('package',this.combos.list[index].package);
			},
			waySel (val){
				this.way.result.length>1?this.way.result.shift():'';
			},//是否預約選擇
			pay (){//立即购买
				if(this.$store.state.isLogin){
					if(JSON.parse(localStorage.getItem('user')) && (JSON.parse(localStorage.getItem('user')).birth && JSON.parse(localStorage.getItem('user')).sex != 0 )) {
						isPay().then(res => {
							// console.log(res);
							if(res.status == 1){
								if(res.data.tips == 0){
									this.complete = true;
									
									if(res.data.package == 4){
										this.yjqz = true;
									}
								}else{
									this.hasReport = true;
								}
							}else{
								this.way.show = true;
							}
						})
					} else {
						this.$router.push('/regist/perfectInfo');
					}
				}else{
					this.way.show = true;
				}
			},
			toQuestion (){
				if(sessionStorage.getItem('package') == null){
					sessionStorage.setItem('package',this.combos.active.package);
				}
				this.$router.push({path: "/payment/0"}).catch( err => {


					// console.log(err)
				});
			}
		}
	}
</script>

<style scoped>
	.qjc-theme-bg >>> .van-cell{
		padding: 0!important;
	}
	.pro-code >>> input{
		width: 3.18rem;
		font-size: 0.28rem;
		color: #D0D7F5;
		background-color: #5873E9;
		border-radius: 0.37rem;
	}
	.pro-code >>> input::-webkit-input-placeholder{
		font-size: 0.28rem;
	    color: #D0D7F5;
	}
	.pro-code >>> input::-moz-placeholder{
		font-size: 0.28rem;
	    color: #D0D7F5;
	}
	.pro-code >>> input:-ms-input-placeholder{
		font-size: 0.28rem;
	    color: #D0D7F5;
	}
	
	.right >>> .iconchahao{
		font-size: 0.2rem;
	}
</style>
<style lang="scss" scoped>
	@import '../../assets/iconfont/iconfont.css';
	
	.main{
		padding-top: 0.88rem;
	}
	.combos{
		width: 7.02rem;
		margin-top: 0.24rem;
		border-radius: 0.16rem;
		
		.combos-name{
			border-bottom: 0.01rem solid #DCDEE5;
			
			li{
				width: 33.333333%;
				padding: 0.3rem 0;
				position: relative;
				
				div{
					padding: 0 0.5rem;
					height: 0.32rem;
					line-height: 0.32rem;
				}
				&:after{
					content: '';
					display: block;
					width: 0rem;
					position: absolute;
					left: 50%;
					bottom: 0;
					transform: translateX(-50%);
					border-bottom: 0.06rem solid transparent;
					transition: all 0.3s linear;
				}
				&:not(:last-child) div{
					border-right: 0.01rem solid #DCDEE5;
				}
				&.active div{
					font-weight: bold;
					color: #6681FA;
				}
				&.active:after{
					width: 1.25rem;
					border-bottom-color: #6681FA;
				}
			}
		}
		.combos-detail{
			padding: 0 0.32rem 0.4rem;
			margin-bottom: 0.8rem;
			
			.combos-detail-tit{
				display: flex;
				align-items: center;
				height: 1.23rem;
				line-height: 1.23rem;
				border-bottom: 0.01rem solid #DCDEE5;
				
				.van-icon{
					margin-left: 0.1rem;
					vertical-align: middle;
					transform: rotateZ(0deg);
					transition: all .3s;
				}
				.van-icon.on{
					transform: rotateZ(-180deg);
				}
				.left{
					width: 60%;
					
					span{
						font-weight: 500;
					}
				}
				.right{
					width: 40%;
					font-size: 0.24rem;
					line-height: 0.28rem;
					vertical-align: middle;
				}
			}
			.describe{
				width: 3.1rem;
				line-height: 0.36rem;
				font-size: 0.24rem;
			}
		}
		.price{
			padding-top: 0.48rem;
			
			sup,sub{
				font-size: 0.2rem;
				font-weight: bold;
			}
			sup{
				vertical-align: top;
			}
			sub{
				vertical-align: text-bottom;
			}
			b{
				font-size: 0.5rem;
				display: inline-block;
				height: 0.5rem;
				line-height: 0.5rem;
			}
		}
		.org-price{
			font-size: 0.24rem;
			font-weight: 400;
			display: inline-block;
			color: #777f8f;
			
			&::before{
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				height: 0.02rem;
				min-height: 1px;
				margin-top: -0.01rem;
				background-color: #777f8f;
			}
		}
	}
	.pro-code{
		width: 4.46rem;
		height: 0.74rem;
		line-height: 0.74rem;
		margin: 0.48rem auto;
		background-color: transparent;
	}
	.is-book{
		width: 94%;
		margin-top: -0.6rem;
		padding: 0.4rem 0.3rem;
		border-radius: 0.08rem;
		overflow-y: visible;
		.way{
			max-height: 100%;
			overflow-y: auto;
		}
		
		ul{
			color: #777F8F;
			line-height: 0.46rem;
			
			li{
				position: relative;
				font-weight: 400;
				padding-left: 0.2rem;
				
				&::before{
					content: '';
					position: absolute;
					left: 0;
					top: 0.5em;
					width: 0.08rem;
					height: 0.08rem;
					background-color: #777F8F;
					border-radius: 50%;
				}
				.van-icon{
					display: inline-block;
					width: 0.1rem;
					vertical-align: middle;
					font-size: 0.2rem;
					line-height: 0.46rem;
				}
			}
		}
		.van-checkbox-group{
			margin-top: 0.32rem;
			
			.van-checkbox{
				line-height: 0.48rem;
				align-items: baseline;
				margin-bottom: 0.1rem;
			}
			.bk-price::before{
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				height: 0.02rem;
				min-height: 1px;
				margin-top: -0.01rem;
				background-color: #777f8f;
			}
		}
		.van-button{
			border-radius: 0.08rem;
			margin-top: 0.32rem;
		}
		.buy-tips{
			color: #777F8F;
			margin-top: 0.12rem;
		}
		.close{
			width: 0.72rem;
			height: 0.72rem;
			padding-top: 0.22rem;
			left: 50%;
			bottom: -1.22rem;
			margin-left: -0.36rem;
			border: 0.02rem solid #fff;
		}
	}
	.assess-nodone{
		width: 6.3rem;
		padding: 0.56rem;
		border-radius: 0.16rem;
		overflow-y: visible;
		
		p{
			line-height: 0.52rem;
			margin: 0.3rem 0;
		}
		.van-button{
			width: 2.5rem;
			line-height: 0.76rem;
			margin: 0 0.04rem;
		}
		.close{
			width: 0.72rem;
			height: 0.72rem;
			padding-top: 0.22rem;
			left: 50%;
			bottom: -1.72rem;
			margin-left: -0.36rem;
			border: 0.01rem solid #fff;
		}
		
		&.yjqz-alert{
			width: 6.5rem;
			height: 10.14rem;
			padding: 0;
			background-color: transparent;
			
			.yjqz-box{
				.van-button{
					position: absolute;
					left: 4%;
					bottom: 0.48rem;
					width: 91%;
					height: 0.74rem;
					border-radius: 0.08rem;
				}
			}
		}
	}
	.has-report{
		width: 5.78rem;
		padding: 0.4rem;
		border-radius: 0.08rem;
		overflow: visible;
		
		&>div.qjc-texta-l{
			color: #777F8F;
			font-size: 0.26rem;
			line-height: 0.4rem;
			font-weight: 400;
			margin: 0.32rem 0 0.4rem;
		}
		.van-button{
			width: 100%;
			height: 0.88rem;
			line-height: 0.88rem;
			border-radius: 0.08rem;
		}
		.close{
			width: 0.72rem;
			height: 0.72rem;
			padding-top: 0.21rem;
			left: 50%;
			bottom: -1.5rem;
			margin-left: -0.36rem;
			border: 0.01rem solid #fff;
		}
	}
	footer{
		color: #FEFEFE;
		font-size: 0.2rem;
		padding: 0.1rem 0 0.16rem;
	}
</style>
